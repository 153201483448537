import UserProfile from "../models/UserProfile";
import WebChatConfigurationEx from "../models/WebChatConfigurationEx";

export async function fetchWebChatWelcomeMessage(dealerId: string, conversationId: string, locale: string, config: WebChatConfigurationEx, userProfile: UserProfile, currentTime: string): Promise<any | null> {
	return await fetch(`api/webchatwelcome/${dealerId}/${conversationId}/${locale}`,
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'post',
            body: JSON.stringify({configuration: config, userProfile: userProfile, currentTime: currentTime})
        }
    ).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        });
}