import { UserProfileType } from './UserProfileType';

export default class UserProfile {
	id: string;
	type: UserProfileType;
	name: string;
	phone: string;
	email: string;
	profilePictureUrl: string;
	preloadQuery : string;
}
