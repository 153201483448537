export function postTrackUser(dealerId: string, userId: string, conversationId: string, parentUrl: string, action : string, segment : string): Promise<void> {
	return fetch(`api/webchat/t/${dealerId}`,
		{
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			method: 'post',
			body: JSON.stringify({ userId: userId, conversationId: conversationId, parentUrl: parentUrl, action: action, segment: segment })
		})
		.then(response => {
			if (!response.ok) {
			}
			return;
		});
}
