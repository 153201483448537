export async function postTrackViews(dealerId: string, segment: string, isUniqueVisitor: boolean): Promise<void> {
    try {
        // make api call
        const response = await fetch('api/track/view', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'post',
            body: JSON.stringify({ dealerId: dealerId, segment: segment, isUniqueVisitor: isUniqueVisitor })
        });

        if (!response.ok) {
            console.log("post track views response not ok: ", response);
        }
        return;

    } catch (error) {
        console.log("post track views has error: ", error);
    }
} 

export async function postTrackConversations(dealerId: string, segment: string): Promise<void> {
    try {
        // make api call
        const response = await fetch('api/track/conversation', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'post',
            body: JSON.stringify({ dealerId: dealerId, segment: segment })
        });

        if (!response.ok) {
            console.log("post track conversations response not ok: ", response);
        }
        return;
        
    } catch (error) {
        console.log("post track conversations has error: ", error);
    }
} 
