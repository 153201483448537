import * as React from 'react';
import './WebchatSendIcon.css';

const WebchatSendIcon = () => {
	return (
		<svg className="webchat-send-icon-svg" viewBox="0 0 45.7 33.8" style={{ width: 28, height: 28 }}>
			<path clipRule="evenodd" d="M8.55 25.25l21.67-7.25H11zm2.41-9.47h19.26l-21.67-7.23zm-6 13l4-11.9L5 5l35.7 11.9z"/>
		</svg>
	);
}

export default WebchatSendIcon;