import * as React from 'react';
import { lazy, Suspense } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './i18n';
import App from './App';
import { createRoot } from 'react-dom/client';

const ChatHead = lazy(() => import('./components/ChatHead/ChatHead'));

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
	<BrowserRouter basename={baseUrl}>
		<Suspense fallback={<div></div>}>
			<Routes>
				<Route path=":dealerId" element={<App/>} />
				<Route path=":dealerId/button" element={<ChatHead/>} />
			</Routes>
		</Suspense>
	</BrowserRouter>
);
