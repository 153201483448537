export async function initializeGATag(trackingId: string, dimension: string, value: string) {
    if (typeof window === "undefined" || typeof document === "undefined") {
        return;
    }

    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=" + trackingId;
    document.body.appendChild(script);

    const dataScript = document.createElement("script");
    dataScript.innerHTML = "window[\"dataLayer\"] = window[\"dataLayer\"] || []; function gtag() {window[\"dataLayer\"].push(arguments);}"
    document.body.appendChild(dataScript);

    gtagJs();
    gtagConfig(trackingId, dimension, value);
}

export async function gtagJs() {
    if (typeof window === "undefined") {
        return;
    }

    window["gtag"]('js', new Date());
};

export async function gtagConfig(trackingId: string, dimension: string, value: string, sendPageView?: boolean) {
    if (typeof window === "undefined") {
        return;
    }
    
    if (dimension !== '' && value !== '' && value !== null && value !== undefined) {
        if (sendPageView === false) {
            window["gtag"]('config', trackingId, {[dimension]: value, send_page_view: false});
        } else {
            window["gtag"]('config', trackingId, {[dimension]: value});
        }
    } else {
        if (sendPageView === false) {
            window["gtag"]('config', trackingId, {send_page_view: false});
        } else {
            window["gtag"]('config', trackingId);
        }
    }
};
export async function gtagConfigNoCookie(trackingId: string, dimension: string, value: string, sendPageView?: boolean) {
    if (typeof window === "undefined" || !window["gtag"]) {
        return;
    }

    if (dimension !== '' && value !== '' && value !== null && value !== undefined) {
        if (sendPageView === false) {
            window["gtag"]('config', trackingId, { [dimension]: value, 'cookie_update': false, send_page_view: false });
        } else {
            window["gtag"]('config', trackingId, { [dimension]: value, 'cookie_update': false });
        }
    } else {
        if (sendPageView === false) {
            window["gtag"]('config', trackingId, { 'cookie_update': false, send_page_view: false });
        } else {
            window["gtag"]('config', trackingId, { 'cookie_update': false });
        }
    }
    window["gtag"]('set', {
        'cookie_flags': 'SameSite=None;Secure'
    });
};


export async function gtagEvent(category: string, action: string, trackingId: string) {
    if (typeof window === "undefined") {
        return;
    }

    window["gtag"]('event', action, {category: category, action: action, send_to: trackingId});
};