import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './resources/en.json';
import translationES from './resources/es.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
	"en-US": {
		translation: translationEN
    },
    "es-ES": {
		translation: translationES
	}
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
        resources: resources,
		lng: 'en-US',
		keySeparator: false, // we do not use keys in form messages.welcome
		interpolation: {
			escapeValue: false // react already safes from xss
		}
	});

export default i18n;
