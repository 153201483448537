export function postHubSpotContactNote(contactId: string): Promise<any | null> {
    return fetch(`api/webchat/hubspot/${contactId}`, {method: 'post'})
    .then(response => {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response.json();
    });
}



