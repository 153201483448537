import React from 'react';
import './Tooltip.css';

const Tooltip = ({ 
    children,
    className = '',
    placement,
    title,
    active=false
    }) => {
    
	return (
		<div className={className + " tooltip-wrapper"}>
            <div>
                {children}
            </div>
            {active && (
                <span className={placement + " tooltip-span"}>
                    {title}
                </span>
            )}
        </div>
	);
}

export default Tooltip;
