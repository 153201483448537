import DirectLineCredentials from "../models/DirectLineCredentials";

export function fetchCredentials(dealerId: string, userId: string | null): Promise <DirectLineCredentials> {
	let options = {
		method: 'POST',
		body: JSON.stringify({ 'dealerId': dealerId, 'userId': userId }),
		headers: {
			'Content-Type': 'application/json'
		}
	};
	return fetch(`api/auth/token/generate`, options)
		.then(response => {
			if (!response.ok) {
				throw Error(response.statusText);
			}
			return response.json();
		})
}
