import React, { useState } from 'react';
import './Button.css';
import Tooltip from '../Tooltip/Tooltip';

const Button = ({ 
    children,
    className = "",
    disabled = false,
    id = null,
    onClick = (event=null) => {},
    style = {},
    title = "",
    type = "text",
    tooltip = false,
    tooltipClassName = "",
    tooltipPlacement = "right",
    tooltipTitle = "",
    }) => {

    // Button types: text, icon, sidebar-icon

    const [active, setActive] = useState(false); 

    let timeout;

    function showToolTip() {
        timeout = setTimeout(() => {
            setActive(true);
        }, 200);
    }

    function hideToolTip() {
        clearInterval(timeout);
        setActive(false);
    }

    function buttonFocused(button) {
        timeout = setTimeout(() => {
            setActive(true);
        }, 70);

        const offFocusListener = (event) => {
            clearInterval(timeout);
            setActive(false);
            removeFocusListener();
        }
        
        const removeFocusListener = () => {
            button.removeEventListener('focusout', offFocusListener);
        }
        
        button.addEventListener('focusout', offFocusListener);
        
        // If button is clicked, remove focus after clicking
        button.addEventListener('mouseup', () => {
            button.blur();
        })
    }

	return (
        (tooltip ? 
            <Tooltip
                className={tooltipClassName}
                placement={tooltipPlacement}
                title={tooltipTitle}
                active={active}
            >
                <button 
                    className={"button-component " + className + " button-" + type}
                    disabled={disabled}
                    id={id}
                    onClick={onClick}
                    style={style}
                    type={(type === "submit" ? "submit" : "button")}
                    onMouseEnter={showToolTip}
                    onMouseLeave={hideToolTip}
                    onFocus={(e) => buttonFocused(e.target)}
                >
                    <span className={"button-label-" + type}>
                        {children}
                    </span>
                </button>
            </Tooltip>
            :
            <button 
                className={"button-component " + className + " button-" + type}
                disabled={disabled}
                id={id}
                onClick={onClick}
                style={style}
                title={title}
                type={(type === "submit" ? "submit" : "button")}
            >
                <span className={"button-label-" + type}>
                    {children}
                </span>
            </button>
        )
	);
}

export default Button;
